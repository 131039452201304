"use strict";

// var wscr=screen.width || window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
// var hscr=screen.height || window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
// hrzwdt=wscr;
// if (wscr>hscr) hrzwdt=hscr;
// var isMobile=(hrzwdt<640?true:false);

var ModalApp = {};
ModalApp.ModalProcess = function (parameters) {
  this.id = parameters['id'] || 'modal';
  this.selector = parameters['selector'] || '';
  this.title = parameters['title'] || 'Заголовок модального окна';
  this.body = parameters['body'] || 'Содержимое модального окна';
  this.footer = parameters['footer'] || '<button type="button" class="btn btn-default" data-dismiss="modal">Закрыть</button>';
  this.content = '<div id="'+this.id+'" class="modal fade" tabindex="-1" role="dialog">'+
    '<div class="modal-dialog" role="document">'+
      '<div class="modal-content">'+
        '<div class="modal-header">'+
          '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+
          '<h4 class="modal-title">'+this.title+'</h4>'+
        '</div>'+
        '<div class="modal-body">'+this.body+'</div>'+
        '<div class="modal-footer">'+this.footer+'</div>'+
      '</div>'+
    '</div>'+
  '</div>';
  this.init = function() {
    if ($('#'+this.id).length==0) {
      $('body').prepend(this.content);
    }
    if (this.selector) {
      $(document).on('click',this.selector, $.proxy(this.showModal,this));
    }
  }
}
ModalApp.ModalProcess.prototype.changeTitle = function(content) {
  $('#' + this.id + ' .modal-title').html(content);
};
ModalApp.ModalProcess.prototype.changeBody = function(content) {
  $('#' + this.id + ' .modal-body').html(content);
};
ModalApp.ModalProcess.prototype.changeFooter = function(content) {
  $('#' + this.id + ' .modal-footer').html(content);
};
ModalApp.ModalProcess.prototype.showModal = function() {
  $('#' + this.id).modal('show');
};
ModalApp.ModalProcess.prototype.hideModal = function() {
  $('#' + this.id).modal('hide');
};
ModalApp.ModalProcess.prototype.updateModal = function() {
  $('#' + this.id).modal('handleUpdate');
};

function switchSbjs(sb){
  //console.log(sb);
  var sbCurrentTyp = sb.current.typ;
  var sbCurrentSrc = sb.current.src;
  var sbCurrentCmp = sb.current.cmp;
  var traffic_source_1c_name = '5 ELEKTROAUTOMATIKA - Форма/Заказ с сайта - ';
  var type_traffic;
  var type_traffic_1c;
  switch (sbCurrentTyp) {
    case 'utm':
      type_traffic = 'Рекламная кампания:';
      type_traffic_1c = 'РЕКЛАМА - ' + sbCurrentSrc;
      break;
    case 'organic':
      type_traffic = 'Поисковая выдача:';
      type_traffic_1c = 'ПОИСК (' + sbCurrentSrc + ')';
      break;
    case 'referral':
      type_traffic = 'Переход по ссылке:';
      type_traffic_1c = 'ПЕРЕХОД ПО ССЫЛКЕ (' + sbCurrentSrc + ')';
      break;
    case 'typein':
      type_traffic = 'Прямой заход:';
      type_traffic_1c = 'ПРЯМОЙ ЗАХОД';
      break;
    default:
      type_traffic = sbCurrentTyp;
      type_traffic_1c = sbCurrentTyp;
  }
  traffic_source_1c_name = traffic_source_1c_name + type_traffic_1c;

  var sb_type_traffic = type_traffic + ' ' + sbCurrentSrc + ' ' + sbCurrentCmp;
  localStorage.setItem("sb_type_traffic", sb_type_traffic);

  $(".traffic_source").val(localStorage.getItem("sb_type_traffic"));
  $('.traffic_source').after('<input class="traffic_source_1c" type="hidden" name="traffic_source_1c" value="' + traffic_source_1c_name + '">');
  $(".location").val(window.location.href);
  $(".referrer").val(document.referrer);

  $.getJSON('//ipinfo.io', function(data){
    if (sb.first_add.fd != sb.current_add.fd) {
      $('.usercity').after('<input class="user_ip" type="hidden" name="user_ip" value="' + data.ip + ' (' + data.country + ', ' + data.region + ', ' + data.city + '). <br><b><i>Первое посещение</i></b>: ' + sb.first_add.fd + ' (' + sb.first.typ + ')">');
    } else {
      $('.usercity').after('<input class="user_ip" type="hidden" name="user_ip" value="' + data.ip + ' (' + data.country + ', ' + data.region + ', ' + data.city + '). <br><b><i>Первое посещение</i></b>: ранее клиент не заходил!">');
    }
  });
}
function map() {
  ymaps.ready(init);
  function init() {
    myMap = new ymaps.Map('sec9', {
      center: [56.110917, 47.305163],
      zoom: 17,
      controls: ['zoomControl']
    });
    myMap.behaviors.disable('scrollZoom');
    myMap.geoObjects.add(
      new ymaps.Placemark(
        [56.111840, 47.304776],
        {
          iconContent: 'Электроавтоматика',
          balloonContentHeader: 'НПО "Электроавтоматика"',
          balloonContent: 'Чебоксары, Дорожный проезд,12 пом.6'
        }, {
          iconLayout: 'default#imageWithContent',
          iconImageHref: 'img/baloon2.png',
          iconImageSize: [14, 14],
          iconContentOffset: [20, 0],
          iconImageOffset: [0, -15]
        }
      )
    );
  }
}
// получение GA ClientID
function get_ga_clid() {
  var match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
  var raw = (match) ? decodeURIComponent(match[1]) : null;
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/);
  }
  var gacid = (match) ? match[1] : null;
  if (gacid) {
    return gacid;
  }
}
// получение локализации по ip-адресу (dadata.ru)
function detectAddressByIp(ip) {
  var token = "a51fe07f52416f66fe3e4d67359475fac8f9a9f2";
  var serviceUrl = "//suggestions.dadata.ru/suggestions/api/4_1/rs/detectAddressByIp";
  if (ip) {
    serviceUrl += "?ip=" + ip;
  }
  var params = {
    type: "GET",
    contentType: "application/json",
    headers: {
      "Authorization": "Token " + token
    }
  };
  return $.ajax(serviceUrl, params);
}
// получение локализации по справочнику КЛАДР
function detect(name, id) {
  var ip = "";
  //var ip = "89.179.10.10"; // Пермь
  detectAddressByIp(ip).done(function(response) {
    //console.log(response);
    $.post(
      '/php/ajax_findKladr.php',
      {addressCity: response.location.data.city + " " + response.location.data.region},
      function(data, textStatus, xhr) {
        data = JSON.parse(data);
        $(name).val(data.name);
        $(id).val(data.code);
        //$(id + ".d-calculation_item-title").val(data.name);
        //$(id).next(".d-calculation_item-title").text(data.name);
        if (data.name.indexOf(', ') !== -1) {
          $(id).next(".d-calculation_item-title").text(data.name.split(', ')[0]);
        } else {
          $(id).next(".d-calculation_item-title").text(data.name);
        }
        console.log(data);
      }
    );
  })
  .fail(function(jqXHR, textStatus, errorThrown) {
    console.log(textStatus);
    console.log(errorThrown);
  });
}
// получение локализации по справочнику КЛАДР (autocomplete)
function filter_autocomplete(filter_name, filter_id) {
  $(filter_name).autocomplete({
    source: function(request,response) {
      $.ajax({
        url: "/php/ajax_findKladr_autocomplete.php",
        data: {
          q: function () { return $(filter_name).val() },
          name_startsWith: function () { return $(filter_name).val() }
        },
        success: function (data) {
          data = JSON.parse(data);
          response($.map(data, function(item){
            return{
              label: item.name,
              value: item.name,
              id: item.code
            }
          }));
        }
      });
    },
    minLength: 2,
    delay: 200,
    select: function(event,ui) {
      console.log(ui.item);
      $(filter_name).val(ui.item.label);
      $(filter_id).val(ui.item.id);
    }
  });
}



$(document).ready(function($) {
  /*Регион в поле*/
  ymaps.ready(
    function(){
      var ymapsGeolocation = ymaps.geolocation;
      if (ymapsGeolocation) {
        console.log(ymapsGeolocation.country + ', ' + ymapsGeolocation.region + ', ' + ymapsGeolocation.city);
        $(".usercity").val(ymapsGeolocation.country + ', ' + ymapsGeolocation.region + ', ' + ymapsGeolocation.city);
      } else {
        console.log('Не удалось установить местоположение');
      }
    });
  /*Определение источника посетителя*/
  sbjs.init({
    domain: {
      //host: 'new.elektroautomatika.ru',
      isolate: false
    },
    lifetime: 6,
    session_length: 30,
    callback: switchSbjs
  });


  var delivery_calculation_form = $('form#CalculatePriceDelivery');
  var filter_arrivalCity_name = delivery_calculation_form.find('input[name=arrivalCity]');
  var filter_arrivalCity_id = delivery_calculation_form.find('input[name=arrivalCityID]');
  $(".ui-autocomplete-input").click(function(event) {
    filter_arrivalCity_name.val("");
    filter_arrivalCity_id.val("");
  });

  (function quantityProducts() {
    var $quantityArrowMinus = $(".quantity-arrow-minus");
    var $quantityArrowPlus = $(".quantity-arrow-plus");
    var $quantityNum = $(".quantity-num");

    $quantityArrowMinus.click(quantityMinus);
    $quantityArrowPlus.click(quantityPlus);

    function quantityMinus() {
      event.preventDefault();
      if ($quantityNum.val() > 1) {
        $quantityNum.val(+$quantityNum.val() - 1);
      }
    }
    function quantityPlus() {
      event.preventDefault();
      $quantityNum.val(+$quantityNum.val() + 1);
    }
  })();

  feather.replace();
  map();
  var ga_clid = get_ga_clid();
  //console.log(ga_clid);
  $("input[name=ga_clientID]").val(ga_clid);

  var audioTrack_01 = $("#audio_01")[0];
  var audioTrack_02 = $("#audio_02")[0];
  var audioTrack_03 = $("#audio_03")[0];
  var button_play_pause_01 = $('.button_play_pause[data-track="audio01"]');
  var button_play_pause_02 = $('.button_play_pause[data-track="audio02"]');
  var button_play_pause_03 = $('.button_play_pause[data-track="audio03"]');

  button_play_pause_01.on('click',function(){
    if($(this).attr('data-click-state') == 1) {
      $(this).attr('data-click-state', 0);
      button_play_pause_01.removeClass('button_play_pause--animation');
      audioTrack_01.pause();
    } else {
      $(this).attr('data-click-state', 1);
      button_play_pause_01.addClass('button_play_pause--animation');
      audioTrack_01.play();
      button_play_pause_02.attr('data-click-state', 0);
      button_play_pause_02.removeClass('button_play_pause--animation');
      audioTrack_02.pause();
      button_play_pause_03.attr('data-click-state', 0);
      button_play_pause_03.removeClass('button_play_pause--animation');
      audioTrack_03.pause();
    }
  });
  button_play_pause_02.on('click',function(){
    if($(this).attr('data-click-state') == 1) {
      $(this).attr('data-click-state', 0);
      button_play_pause_02.removeClass('button_play_pause--animation');
      audioTrack_02.pause();
    } else {
      $(this).attr('data-click-state', 1);
      button_play_pause_02.addClass('button_play_pause--animation');
      audioTrack_02.play();
      button_play_pause_01.attr('data-click-state', 0);
      button_play_pause_01.removeClass('button_play_pause--animation');
      audioTrack_01.pause();
      button_play_pause_03.attr('data-click-state', 0);
      button_play_pause_03.removeClass('button_play_pause--animation');
      audioTrack_03.pause();
    }
  });
  button_play_pause_03.on('click',function(){
    if($(this).attr('data-click-state') == 1) {
      $(this).attr('data-click-state', 0);
      button_play_pause_03.removeClass('button_play_pause--animation');
      audioTrack_03.pause();
    } else {
      $(this).attr('data-click-state', 1);
      button_play_pause_03.addClass('button_play_pause--animation');
      audioTrack_03.play();
      button_play_pause_01.attr('data-click-state', 0);
      button_play_pause_01.removeClass('button_play_pause--animation');
      audioTrack_01.pause();
      button_play_pause_02.attr('data-click-state', 0);
      button_play_pause_02.removeClass('button_play_pause--animation');
      audioTrack_02.pause();
    }
  });



  var youtubeModal = new ModalApp.ModalProcess({ id: 'youtubeModal' }); // создаём объект "Модальное окно"
  youtubeModal.init();

  $('.modal-show').click(function(){
    youtubeModal.changeTitle($(this).attr('data-title'));
    youtubeModal.changeBody($(this).attr('data-content'));
    youtubeModal.showModal();
  });
  // при скрытии модального окна очищаем его содержимое
  $('#'+youtubeModal.id).on('hidden.bs.modal', function (e) {
    youtubeModal.changeBody('');
  });

  $('.slider').slick({
      infinite: true,
      autoplaySpeed: 3000,
      // fade: true,
      arrows: true,
      dots: true,
      autoplay: true,
      cssEase: "cubic-bezier(0.39, 0.575, 0.565, 1)",
      //centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  );

  $('.catalog_slider').slick({
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        //breakpoint: 770, //600
        breakpoint: 970, //600
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        //breakpoint: 640,
        breakpoint: 540,
        settings: {
          arrows: true,
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
        }
      },
      {
        breakpoint: 460,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 20000,
        settings: "unslick"
      }
    ]
  });


  var $dontLeaveModal = $('#dontLeaveModal');

  // $('.open-dontLeaveModal').click(function(e) {
  //   e.preventDefault();
  //   $dontLeaveModal.modal('show');
  //   //Cookies.set("stop","true");
  // });


  if (!isMobile.any) {
    setInterval(function() {
      if (Cookies.get("stop") !== "true") {
        if ($("body").hasClass("modal-open")) {
          glio.init([ "top", function() {} ]);
        } else {
          glio.init(
            [ 'top',
              function () {
                //$dontLeaveModal.modal('show'); // временно отключил
              }
            ]
          );
        }
      } else {
        glio.init([ "top", function() {} ]);
      }
    }, 20);
  }

  $('input[name$=_phone]').inputmask({
    //mask: "+9{1,3} (999) 999-99-99",
    mask: "+9 (999) 999-99-99",
    clearIncomplete: true,
    showMaskOnHover: false,
    onKeyDown: function onKeyDown(event, buffer, caretPos, opts) {
      if (event.originalEvent.keyCode == 13) {
        if (!$(this).inputmask("isComplete")) event.preventDefault();
      }
      return true;
    }
  });

  $('.menu_open, .menu_close').click(function (e) {
    e.preventDefault();
    $('.menu').toggleClass('active');
  });
  $('.menu2_open, .menu2_close').click(function (e) {
    e.preventDefault();
    $('.menu2').toggleClass('active');
  });
  $(window).scroll(function () {
    $('.menu').removeClass('active');
    $('.menu2').removeClass('active');
  });


  //window.isMobile = window.isMobile || false;

  var filter_derivalCity_name = $('.quiz_questions input[name=derivalCity]');
  var filter_arrivalCity_name = $('.quiz_questions input[name=arrivalCity]');
  var filter_derivalCity_id = $(".quiz_questions input[name=derivalCityID]");
  var filter_arrivalCity_id = $(".quiz_questions input[name=arrivalCityID]");

  detect(filter_arrivalCity_name, filter_arrivalCity_id); // Определение города по ip-адресу для arrivalCity - пункт куда нужно отправить

  //filter_autocomplete(filter_derivalCity_name, filter_derivalCity_id);
  filter_autocomplete(filter_arrivalCity_name, filter_arrivalCity_id);

  var form = $('#CalculatePriceDelivery');
  form.submit(function(event) {
    var data = form.serialize();
    //console.log(data);
    $("#calc").html("Подождите, идет расчет ...");
    $("#calc").attr("disabled", true);
    $(".result tbody .calc_price").html('');
    $(".result tbody .calc_time").html('');
    $("#resultTable").trigger("destroy");
    event.preventDefault();
    $.post(
      '/php/ajax_calculateDelivery.php',
      data,
      function(data, status) {
        $("#calc").html("Рассчитать стоимость");
        $("#calc").attr("disabled", false);
        if( status == 'success' ){
          var output = JSON.parse(data);
          console.log(output);
          $('#pecom .calc_price').text(output.pecom.calc_price);
          $('#pecom .calc_time').text(output.pecom.calc_time);
          $('#dellin .calc_price').text(output.delline.calc_price);
          $('#dellin .calc_time').text(output.delline.calc_time);
          $('#jde .calc_price').text(output.jde.calc_price);
          $('#jde .calc_time').text(output.jde.calc_time);
          $('#kit .calc_price').text(output.kit.calc_price);
          $('#kit .calc_time').text(output.kit.calc_time);
          //if (typeof output.cdek !== 'undefined') {
            $('#cdek .calc_price').text(output.cdek.calc_price);
            $('#cdek .calc_time').text(output.cdek.calc_time);
          //}
          $("#resultTable").tablesorter({
            sortList:[[2,0]],
            widgets:['zebra'],
            //theme: 'materialize',
            cancelSelection:true,
            headers:{
              0:{
                sorter:false
              }
            }
          });
        }else{
          console.log("В процессе отправки произошла ошибка!");
        }
      }
    );
  });

  var form_TestDriveOrder = $('#TestDriveOrder');
  form_TestDriveOrder.submit(function(event) {
    var data_form = form_TestDriveOrder.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        console.log(status);
        if(status == 'success'){
          // var output = JSON.parse(data);
          console.log(data);
          form_TestDriveOrder.trigger('reset');
          //$('#goodSendModal').modal('show');
          if(form_TestDriveOrder.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });
  var form_TestDriveOrder2 = $('#TestDriveOrder2');
  form_TestDriveOrder2.submit(function(event) {
    var data_form = form_TestDriveOrder2.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          // var output = JSON.parse(data);
          console.log(data);
          form_TestDriveOrder2.trigger('reset');
          $('#testDriveModal2').modal('hide');
          //$('#goodSendModal').modal('show');
          if(form_TestDriveOrder2.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });

  var form_RequestCallback = $('#RequestCallback');
  form_RequestCallback.submit(function(event) {
    var data_form = form_RequestCallback.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          // var output = JSON.parse(data);
          console.log(data);
          $('.menu').removeClass('active');
          form_RequestCallback.trigger('reset');
          //$('#goodSendModal').modal('show');
          if(form_RequestCallback.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });

  var form_BookLaserEngraving = $('#BookLaserEngraving');
  form_BookLaserEngraving.submit(function(event) {
    var data_form = form_BookLaserEngraving.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          // var output = JSON.parse(data);
          console.log(data);
          form_BookLaserEngraving.trigger('reset');
          //$('#goodSendModal').modal('show');
          if(form_BookLaserEngraving.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });


  var form_Order = $('#OrderForm');
  var orderModal = $('#orderModal');

  orderModal.on('show.bs.modal', function (e) {
    var product = $(e.relatedTarget).data('product');
    var discount = $(e.relatedTarget).data('discount');
    console.log(product);
    console.log(discount);
    form_Order.find("input[name=order_product]").val(product);
  });
  form_Order.submit(function(event) {
    var data_form = form_Order.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          // var output = JSON.parse(data);
          console.log(data);
          form_Order.trigger('reset');
          $('#orderModal').modal('hide');
          //$('#goodSendModal').modal('show');
          if(form_Order.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });

  var form_SendCatalog = $('#SendCatalog');
  form_SendCatalog.submit(function(event) {
    var buttonSubmit = $("#SendCatalog button[type=submit]");
    buttonSubmit.html("Идет отправка ...");
    buttonSubmit.attr("disabled", true);
    var data_form = form_SendCatalog.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          buttonSubmit.html("Скачать каталог");
          buttonSubmit.attr("disabled", false);
          // var output = JSON.parse(data);
          console.log(data);
          form_SendCatalog.trigger('reset');
          //$('#goodSendModal').modal('show');
          if(form_SendCatalog.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });
  var form_SendCatalog2 = $('#SendCatalog2');
  form_SendCatalog2.submit(function(event) {
    var buttonSubmit = $("#SendCatalog2 button[type=submit]");
    buttonSubmit.html("Идет отправка ...");
    buttonSubmit.attr("disabled", true);
    var data_form = form_SendCatalog2.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          buttonSubmit.html("Скачать каталог");
          buttonSubmit.attr("disabled", false);
          // var output = JSON.parse(data);
          console.log(data);
          form_SendCatalog2.trigger('reset');
          //$('#goodSendModal').modal('show');
          if(form_SendCatalog2.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });

  var form_promoCode = $('#promoCodeForm');
  form_promoCode.submit(function(event) {
    var data_form = form_promoCode.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          // var output = JSON.parse(data);
          console.log(data);
          form_promoCode.trigger('reset');
          ////$('#dontLeaveModal').modal('hide');
          //$('#goodSendModal').modal('show');
          if(form_promoCode.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });

  var form_quizRight = $('#QuizRightForm');
  form_quizRight.submit(function(event) {
    var data_form = form_quizRight.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          // var output = JSON.parse(data);
          console.log(data);
          form_quizRight.trigger('reset');
          //$('#goodSendModal').modal('show');
          if(form_quizRight.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });

  var form_quizRequestCallback = $('#QuizRequestCallback');
  form_quizRequestCallback.submit(function(event) {
    var data_form = form_quizRequestCallback.serialize();
    console.log(data_form);
    event.preventDefault();
    $.post(
      '/php/mail.php',
      data_form,
      function(data, status) {
        if( status == 'success' ){
          // var output = JSON.parse(data);
          console.log(data);
          form_quizRequestCallback.trigger('reset');
          //$('#goodSendModal').modal('show');
          if(form_quizRequestCallback.find("input[name$=_checkbox]").is(':checked')) {
            window.location.href = "/thanks.html";
          } else {
            console.log("Ошибка! Подтвердите согласие");
            $('#badSendModal').modal('show');
          }
        }else{
          console.log("В процессе отправки произошла ошибка!");
          $('#badSendModal').modal('show');
        }
      }
    );
  });

  var quiz__question = $('#quiz__question');
  var question__title = $('#question__title');
  var progress__label = $('#progress__label');
  var progress__bar = $('#progress__bar');
  var test__kviz_finish = $('#test__kviz_finish');

  quiz__question.find('.js-quiz__variants').click(function(event) {
    var step = $(this).data('step');
    var step_val = $(this).find('.js-card_text').text();
    console.log(step_val);

    if (step.indexOf("kviz01_") == 0) {
      form_quizRequestCallback.find('input[name=step_01]').val(step_val);
      question__title.text('Какой размер резьбы необходим?');
      progress__label.text('Осталось 5 вопросов из 6');
      progress__bar.html('<span style="width: 16.67%;"></span>');
      $('#test__kviz01').hide();
      $('#test__kviz02').show();
    } else if (step.indexOf("kviz02_") == 0) {
      form_quizRequestCallback.find('input[name=step_02]').val(step_val);
      question__title.text('Выберите материал пьезокнопки');
      progress__label.text('Осталось 4 вопроса из 6');
      progress__bar.html('<span style="width: 33.34%;"></span>');
      $('#test__kviz02').hide();
      $('#test__kviz03').show();
      var step4_arr;
      switch (step_val) {
        case 'M16*1':
        case 'M18*1':
          step4_arr = ['F1','F2','F3','F4','F5','F6','F7'];
          break;
        case 'M22*1,5':
          step4_arr = ['M1','M2','M3','L1','L2','R1','R2','R3','R4'];
          break;
        case 'M27*1,5':
        case 'M30*1,5':
          step4_arr = ['R1','R2','R3','R4'];
          break;
        default:
          console.log('Я таких значений не знаю');
      }
      $('#test__kviz04').data('step4_arr', step4_arr);
    } else if (step.indexOf("kviz03_") == 0) {
      form_quizRequestCallback.find('input[name=step_03]').val(step_val);
      question__title.text('Выберите тип корпуса');
      progress__label.text('Осталось 3 вопроса из 6');
      progress__bar.html('<span style="width: 50.01%;"></span>');
      $('#test__kviz03').hide();

      var step4_arr = $('#test__kviz04').data('step4_arr');
      $("#test__kviz04 .js-quiz__variants").each(function(index){
        var step4_val = $(this).data('step');
        step4_val = step4_val.substr(7);
        //console.log("step4_val: " + step4_val);
        var step4_val_inArr = $.inArray(step4_val, step4_arr);
        //console.log("step4_val_inArr: " + step4_val_inArr);
        if (step4_val_inArr >= 0) {
          //$(this).show();
          console.log(index + ": " + step4_val);
        } else{
          $(this).parent().detach();
        }
      });

      $('#test__kviz04').show();
      $('.test__kviz04 .row').slick({
        infinite: true,
        autoplaySpeed: 3000,
        // fade: true,
        arrows: true,
        dots: true,
        autoplay: true,
        cssEase: "cubic-bezier(0.39, 0.575, 0.565, 1)",
        //centerMode: true,
        slidesToShow: 4,
        //slidesToScroll: 4,
        slidesToScroll: 1,
        responsive: [
          // {
          //   breakpoint: 1024,
          //   settings: {
          //     slidesToShow: 3,
          //     slidesToScroll: 3,
          //     // infinite: true,
          //     // dots: true
          //   }
          // },
          {
            breakpoint: 970, //600
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 460,
            settings: {
              arrows: true,
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    } else if (step.indexOf("kviz04_") == 0) {
      form_quizRequestCallback.find('input[name=step_04]').val(step_val);
      question__title.text('Выберите напряжение');
      progress__label.text('Осталось 2 вопроса из 6');
      progress__bar.html('<span style="width: 66.67%;"></span>');
      $('#test__kviz04').hide();
      $('#test__kviz05').show();
      $('.test__kviz04 .row').slick('unslick');
    } else if (step.indexOf("kviz05_") == 0) {
      form_quizRequestCallback.find('input[name=step_05]').val(step_val);
      question__title.text('Выберите необходимое количество');
      progress__label.text('Остался последний вопрос!');
      progress__bar.html('<span style="width: 83.34%;"></span>');
      $('#test__kviz05').hide();
      $('#test__kviz06').show();
    } else if (step.indexOf("kviz06_") == 0) {
      form_quizRequestCallback.find('input[name=step_06]').val(step_val);
      question__title.text('Отлично! Оставьте контактные данные');
      progress__label.text('Опрос пройден!');
      progress__bar.html('<span style="width: 100%;"></span>');
      $('#test__kviz06').hide();
      $('#test__kviz_finish').show();
    }
  });

  //console.log(ymaps);

  // // $('#fullpage').parallax({imageSrc: '../img/main.jpg'});
  // $('#sec2').parallax({imageSrc: '../img/section_02.jpg'});
  // // $('#sec3').parallax({imageSrc: '../img/section_03.png'});
  // // $('#sec4').parallax({imageSrc: '../img/section_04.png'});
  // // $('#sec5').parallax({imageSrc: '../img/section_05.jpg'});
  // $('#sec6').parallax({imageSrc: '../img/section_06.jpg'});
  // // $('#sec7').parallax({imageSrc: '../img/section_07.jpg'});
  // $('#sec8').parallax({imageSrc: '../img/section_08mod0.jpg'});
  // // $('#sec9').parallax({imageSrc: '../img/section_09.jpg'});

  SmoothScroll({
    stepSize: 185,
    //stepSize: 150,
    animationTime : 400,
    pulseAlgorithm : false,
    pulseScale : 0.1,
    pulseNormalize : 1,
  });

});
